"use client";

import { useEffect, useState } from "react";
import { assetService } from "@/service/content/asset.service";
import { ContentAsset } from "@/models/asset.model";
import { AssetTable } from "./parts/AssetTable";
import { toast } from "@/components/third-party/shadcn/use-toast";
import { AssetDetailDrawer } from "./parts/drawer/DetailDrawer";
import { AssetGenerateDrawer } from "./parts/drawer/GenerateDrawer";
import { Input } from "@/components/third-party/shadcn/input";
import { FilterMenu } from "./parts/FilterMenu";
import { transcriptService } from "@/service/content/transcript.service";
import { TestGenerateQoosModal } from "@/components/shared/modal/transcript/TestGenerateQoosModal";

const PAGE_LIMIT = 30;
interface Filter {
  searchTerm: string;
  moduleId: number;
  pathId: number;
}

export function AssetLibrary() {
  const [assets, setAssets] = useState([] as ContentAsset[]);
  const [activeAsset, setActiveAsset] = useState<ContentAsset>(
    {} as ContentAsset
  );
  const [activeAssetQoosCount, setActiveAssetQoosCount] = useState(0);
  const [generatingAssets, setGeneratingAssets] = useState<ContentAsset[]>(
    [] as ContentAsset[]
  );

  const [filter, setFilter] = useState(null as Filter);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(PAGE_LIMIT);
  const [assetLoading, setAssetLoading] = useState(true);

  const fetchAssets = async (currentFilter?: Filter, currentPage?: number) => {
    setAssetLoading(true);
    const {
      data: assets,
      totalItems,
      totalPage,
    } = await assetService
      .getList(
        {
          includePresignedUrl: true,
          ...(currentFilter ?? filter ?? {}),
        },
        { page: currentPage ?? page, limit }
      )
    .finally(() => setAssetLoading(false));

    setTotalItems(totalItems);
    setTotalPage(totalPage);
    setAssets(assets);

    const activeAsset = assets?.[0];
    const transcript = await transcriptService.get(activeAsset?.id);
    activeAsset.transcript = transcript?.content;

    setActiveAsset(activeAsset);
    setActiveAssetQoosCount(activeAsset?.qoosCount);
  };

  const handleAssetDeleted = (asset: ContentAsset) => {
    setAssets((prevAssets) => {
      const newAssets = prevAssets.filter((a) => a.id !== asset.id);

      // Update active asset
      setActiveAsset(() => newAssets[0] || undefined);

      // Update total items
      setTotalItems(newAssets.length);

      // Update total pages
      setTotalPage((prevPages) => {
        const newTotalPages = Math.ceil(newAssets.length / limit);
        return newTotalPages < prevPages ? newTotalPages : prevPages;
      });

      return newAssets;
    });
  };

  const fetchActiveAsset = async (id?: number) => {
    const assets = await assetService.getList({
      mediaIds: [id ?? activeAsset?.id],
      includePresignedUrl: true,
    });

    const asset = assets?.data?.[0];
    if (!asset) {
      return;
    }

    const transcript = await transcriptService.get(asset?.id);
    asset.transcript = transcript?.content;

    setAssets((prev) => {
      const index = prev.findIndex((a) => a.id === asset.id);
      prev[index] = asset;
      return [...prev];
    });

    setActiveAsset((prev) => {
      return {
        ...prev,
        ...asset,
      };
    });

    setActiveAssetQoosCount(asset?.qoosCount);
  };

  const handleAssetClick = (asset: ContentAsset) => {
    if (activeAsset?.id === asset?.id) return;

    fetchActiveAsset(asset?.id);
  };

  const onFilterChange = (newFilter: { path: number; module: number }) => {
    setFilter((prev) => ({
      ...prev,
      pathId: newFilter.path,
      moduleId: newFilter.module,
    }));
    setPage(1);
  };

  useEffect(() => {
    fetchAssets(filter, page);
  }, [filter, page, limit]);

  return (
    <div className="flex flex-row flex-1 gap-4 max-h-[calc(100vh-40px)] overflow-y-auto ">
      <div className={`flex flex-row gap-2 w-full py-2`}>
        <div className="flex flex-col flex-1 gap-4 max-h-[calc(100vh-120px)] overflow-y-auto">
          <div className="flex items-center space-x-4 w-full mb-4">
            <h2 className="text-2xl font-semibold whitespace-nowrap">
              Asset Library
            </h2>
            <div className="flex-grow flex items-center space-x-2">
              <div className="flex-grow pt-1">
                <Input
                  placeholder="Search by Asset Name..."
                  value={filter?.searchTerm}
                  onChange={(event) => {
                    setFilter((prev) => {
                      return {
                        ...prev,
                        searchTerm: event.target.value,
                      };
                    });
                  }}
                  className="w-full h-10 px-3" // Adjust these values as needed
                />
              </div>
              <FilterMenu onApply={onFilterChange} />
            </div>
          </div>
          <AssetTable
            data={{
              assets,
              totalItems,
              totalPage,
              activeAsset,
            }}
            paging={{
              page,
              onPageChange: (newPage) => {
                setPage(newPage);
                fetchAssets(filter, newPage);
              },
            }}
            generatingAssetIds={generatingAssets.map((a) => a?.id)}
            setActiveAsset={handleAssetClick}
          />
        </div>
        <div className={`flex flex-col gap-4 ${activeAsset ? "" : "hidden"}`}>
          <AssetDetailDrawer
            asset={activeAsset}
            onActiveAssetChange={fetchActiveAsset}
            onDeleted={handleAssetDeleted}
          />

          <AssetGenerateDrawer
            asset={activeAsset}
            generatingAssets={generatingAssets}
            hide={!!activeAssetQoosCount}
            onGenerate={(asset) => {
              setGeneratingAssets((prev) => {
                if (prev.find((a) => a?.id === asset?.id)) {
                  return prev;
                }

                return [...prev, asset];
              });
            }}
            onGenerateComplete={(asset) => {
              setGeneratingAssets((prev) => {
                return prev.filter((a) => a?.id !== asset?.id);
              });

              toast({
                title: `Transcripts, Chunks, and Qoos generated successfully.`,
                description: `Asset generation for ${asset?.originalName} complete.`,
              });

              fetchActiveAsset(asset?.id);
            }}
          />
        </div>
      </div>
    </div>
  );
}
