import { Button } from "@/components/base/Button";
import { Textarea } from "@/components/third-party/shadcn/textarea";
import { transcriptService } from "@/service/content/transcript.service";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AlertDialog } from "../alert/AlertDialog";

export const TranscriptModal = ({
  s3MediaId,
  isOpen,
  onClose,
  transcript,
}: {
  s3MediaId: number;
  isOpen: boolean;
  onClose: any;
  transcript: string;
}) => {
  const [trackedTranscript, setTrackedTranscript] = useState(transcript);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [transcribeWarningOpen, setTranscribeWarningOpen] = useState(false);

  const handleTranscribe = async () => {
    setIsTranscribing(true);
    setTranscribeWarningOpen(false);
    const generator = transcriptService.transcriptGenerator(s3MediaId, true);

    let result = "";
    setTrackedTranscript("");
    for await (const chunk of generator) {
      console.log(chunk); // Log each chunk to the console
      result += chunk;
      setTrackedTranscript(result);
    }

    setIsTranscribing(false);
  }

  const handleClose = () => {
    if (isTranscribing) {
      return;
    }

    setTrackedTranscript(transcript);
    onClose();
  }

  useEffect(() => {
    setTrackedTranscript(transcript);
  }, [transcript]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent p="1">
        <div className="p-4">
          <div className="flex justify-between items-center font-semibold">
            Transcript
          </div>
          <div className="text-[15px] text-[gray] align-center">
            {(trackedTranscript?.split(" ")?.length === 1
              ? 0
              : trackedTranscript?.split(" ")?.length) ?? 0}{" "}
            words · {trackedTranscript?.length ?? 0} characters
          </div>
        </div>

        <Textarea
          disabled
          rows={24}
          className="h-full !text-[black] p-4"
          value={trackedTranscript}
          placeholder="Begin by clicking 'Generate Transcript' in the Toolkit"
        />

        <ModalFooter>
          <Button className="mr-2" variant="outline" onClick={handleClose} disabled={isTranscribing}>Close</Button>
          <Button onClick={() => setTranscribeWarningOpen(true)} loading={isTranscribing} disabled={isTranscribing}>Transcribe</Button>
        </ModalFooter>
      </ModalContent>

      <AlertDialog
        header="Confirm Transcribe"
        body="Are you sure you want to transcribe this asset? This will overwrite the existing transcript."
        isOpen={transcribeWarningOpen}
        onClose={() => setTranscribeWarningOpen(false)}
        onConfirm={handleTranscribe}
      />
    </Modal>
  );
};
