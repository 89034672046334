import React, { useEffect, useState } from "react";
import { Button } from "@/components/third-party/shadcn/button";
import { toast } from "@/components/third-party/shadcn/use-toast";
import { QoosSequenceGenerator } from "@/components/views/assets/uploader/sequence/QooSequenceGenerator";
import { assetService } from "@/service/content/asset.service";
import { AlertDialog } from "@/components/shared/modal/alert/AlertDialog";
import { Badge } from "@/components/third-party/shadcn/badge";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";

export const AssetSequenceModal = ({
  id,
  isOpen,
  onClose,
  onSubmit,
  sequence,
}) => {
  const [trackedSequence, setTrackedSequence] = useState(
    {} as { pathId: number; moduleId: number }
  );
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const cancelRef = React.useRef();

  const handleSequenceChange = ({ path, module }) => {
    setTrackedSequence({ pathId: path?.id, moduleId: module?.id });
  };

  const handleUpdateSequence = async () => {
    setIsConfirmOpen(false);
    await assetService
      .updateSequence(id, trackedSequence)
      .then((sequence) => {
        toast({
          title: "Sequence updated!",
          description: "Sequence has been updated successfully.",
        });
        onSubmit(sequence);
        onClose();
      })
      .catch(() => {
        toast({
          title: "Error!",
          description: "An error occurred while updating the sequence.",
        });
      });
  };

  const openConfirmDialog = () => {
    setIsConfirmOpen(true);
  };

  const closeConfirmDialog = () => {
    setIsConfirmOpen(false);
  };

  useEffect(() => {
    console.log(sequence);
  }, [sequence, sequence?.pathTitle, sequence?.moduleTitle]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent p="1">
          <div className="p-4">
            <div className="flex text-[18px] justify-between font-semibold mb-4">
              Update Sequence
            </div>
            <div className="flex flex-col justify-between font-semibold mb-4">
              <div className="mb-1 font-normal">Current sequence:</div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center">
                  <span className="mr-2 font-normal text-base">Path:</span>
                  <Badge>{sequence?.pathTitle}</Badge>
                </div>
                <div className="flex items-center">
                  <span className="mr-2 font-normal text-base">Module:</span>
                  <Badge>{sequence?.moduleTitle}</Badge>
                </div>
              </div>
            </div>
            <QoosSequenceGenerator onSequenceChange={handleSequenceChange} />
          </div>

          <ModalFooter>
            <Button variant="outline" onClick={onClose} className="mr-1">
              Close
            </Button>
            <Button onClick={openConfirmDialog}>Update</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        header="Confirm Update"
        body="Are you sure you want to update the sequence?"
        isOpen={isConfirmOpen}
        onClose={closeConfirmDialog}
        onConfirm={handleUpdateSequence}
      />
    </>
  );
};
