import { FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useTheme,
} from "@chakra-ui/react";
import { BackIcon, Icons, NextIcon, TrashIcon } from "../icons/CustomIcons";
import { Button } from "../base";
import { DrawerStickyFooter } from "./parts/DrawerStickyFooter";
import { Input } from "../base/Input";
import { Textarea } from "../base/Textarea";
import { toast } from "../third-party/shadcn/use-toast";
import { Separator } from "../third-party/shadcn/separator";
import { QooUploadDoc } from "./parts/QooUploadDoc";
import { QooUploadVideo } from "./parts/QooUploadVideo";
import { chunkService } from "@/service/content/chunk.service";
import { QooModel } from "@/models/qoo.model";

interface Props {
  s3MediaId: number;
  streamId?: number;
  qooIndex: number;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (qoo) => void;
}

export const QooAddDrawer: FC<Props> = ({
  s3MediaId,
  streamId,
  qooIndex,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [formState, setFormState] = useState<any>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleAddItem = (property: "bulletpoint" | "actions" | "tags") => {
    const newQoo = { ...formState };
    newQoo[property] = [...(newQoo[property] ?? []), ""];
    setFormState(newQoo);
  };

  const handleDeleteItem = (
    property: "bulletpoint" | "actions" | "tags",
    index: number
  ) => {
    const newQoo = { ...formState };
    newQoo[property].splice(index, 1);
    setFormState(newQoo);
  };

  const handleSave = async () => {
    const filteredActions = formState.actions?.filter(
      (action: string) => action.trim() !== ""
    );
    const filteredBulletpoints = formState.bulletpoint?.filter(
      (point: string) => point.trim() !== ""
    );
    const filteredTags = formState.tags?.filter(
      (point: string) => point.trim() !== ""
    );

    const payload = {
      ...formState,
      actions: filteredActions,
      bulletpoint: filteredBulletpoints,
      tags: filteredTags,
    };

    setIsSaving(true);
    await chunkService
      .createChunkWithQoo(s3MediaId, streamId, qooIndex, payload)
      .then((qoo: QooModel) => {
        toast({
          title: "Qoo updated",
          description: "Qoo has been updated successfully",
        });

        onSubmit(qoo);
        handleClose();
      })
      .catch(() => {
        toast({
          title: "Error",
          description: "An error occurred while updating qoo",
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleClose = () => {
    onClose();
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: "actions" | "bulletpoint" | "tags"
  ) => {
    const newQoo = { ...formState };
    newQoo[type][index] = e.target.value;
    setFormState(newQoo);
  };

  const handleTextChange = (e: any) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader display="flex" alignItems="center">
          <Flex justifyContent="space-between" width="100%">
            <Box overflow="hidden">
              <div>Add Qoo</div>
            </Box>

            <div className="cursor-pointer" onClick={handleClose}>
              <Icons.Error />
            </div>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <div className="flex flex-col h-[100%] w-full ">
            <div className="flex flex-col align-center gap-4 text-[18px] mt-4">
              <Input
                name="title"
                label="Title"
                value={formState.title}
                onChange={handleTextChange}
              />

              <div className="flex flex-col gap-4 border-[1px] border-gray p-4 rounded-md">
                <div className="flex justify-between items-center">
                  <div className="text-[14px] font-semibold">
                    Actions to Take
                  </div>
                  <div
                    className="text-[14px] font-semibold cursor-pointer"
                    onClick={() => handleAddItem("actions")}
                  >
                    Add Action +
                  </div>
                </div>

                {formState?.actions?.map((action: any, index: number) => (
                  <div key={index} className="flex flex-row gap-2 items-center">
                    <Input
                      name={`action-${index}`}
                      value={action}
                      onChange={(e: any) =>
                        handleInputChange(e, index, "actions")
                      }
                    />
                    <span
                      className="cursor-pointer"
                      onClick={() => handleDeleteItem("actions", index)}
                    >
                      <TrashIcon />
                    </span>
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-4 border-[1px] border-gray p-4 rounded-md">
                <div className="flex justify-between items-center">
                  <div className="text-[14px] font-semibold">
                    Supporting Information
                  </div>
                  <div
                    className="text-[14px] font-semibold cursor-pointer"
                    onClick={() => handleAddItem("bulletpoint")}
                  >
                    Add Item +
                  </div>
                </div>

                {formState?.bulletpoint?.map(
                  (bulletpoint: any, index: number) => (
                    <div
                      key={index}
                      className="flex flex-row gap-2 items-center"
                    >
                      <Input
                        name={`bulletpoint-${index}`}
                        value={bulletpoint}
                        onChange={(e: any) =>
                          handleInputChange(e, index, "bulletpoint")
                        }
                      />
                      <span
                        className="cursor-pointer"
                        onClick={() => handleDeleteItem("bulletpoint", index)}
                      >
                        <TrashIcon />
                      </span>
                    </div>
                  )
                )}
              </div>
              <div className="flex flex-col gap-4 border-[1px] border-gray p-4 rounded-md">
                <div className="flex justify-between items-center">
                  <div className="text-[14px] font-semibold">Tags</div>
                  <div
                    className="text-[14px] font-semibold cursor-pointer"
                    onClick={() => handleAddItem("tags")}
                  >
                    Add Tag +
                  </div>
                </div>

                {formState.tags?.map((tag: any, index: number) => (
                  <div key={index} className="flex flex-row gap-2 items-center">
                    <Input
                      name={`tag-${index}`}
                      value={tag}
                      onChange={(e: any) => handleInputChange(e, index, "tags")}
                    />
                    <span
                      className="cursor-pointer"
                      onClick={() => handleDeleteItem("tags", index)}
                    >
                      <TrashIcon />
                    </span>
                  </div>
                ))}
              </div>

              <QooUploadDoc
                qooId={formState?.id}
                triggerSave={isSaving}
                existingFiles={formState?.documentFiles}
              />

              <div className="flex flex-col gap-4 border-[1px] border-gray p-4 rounded-md">
                <div className="flex flex-col w-full gap-4">
                  <div className="text-[14px] font-semibold">
                    Upload or Generate Video
                    <div className="text-[13px] font-normal text-orange-500">
                      If both an AI Video and uploaded video exist, the AI Video
                      will be used.
                    </div>
                  </div>
                  <QooUploadVideo
                    qooId={formState?.id}
                    triggerSave={isSaving}
                    existingVideo={formState?.video}
                  />

                  {/* Horizontal like with word 'hello world' in the middle */}
                  <div className="flex items-center justify-center gap-4">
                    <div className="border-b-2 border-gray w-1/2"></div>
                    <div className="text-[14px] font-semibold">OR</div>
                    <div className="border-b-2 border-gray w-1/2"></div>
                  </div>

                  {/* automatically horizontal align middle */}
                  <div className="flex items-center justify-center gap-4">
                    <Button
                      className="w-[200px]"
                      onClick={() => alert("Awaiting implementation")}
                    >
                      Generate Video
                    </Button>
                  </div>
                </div>
              </div>

              <Separator />

              <Textarea
                name="anecdote"
                label="Anecodate (Video Script)"
                className="h-full p-5"
                rows={5}
                value={formState.anecdote}
                placeholder="Begin by clicking 'Generate Transcript' in the Toolkit"
                onChange={(e: any) =>
                  handleTextChange({
                    target: { name: "anecdote", value: e.target.value },
                  } as any)
                }
              />
              {/* 
              <Textarea
                name="note"
                label="Additional Details"
                className="h-full p-5"
                rows={5}
                value={formState.note}
                placeholder="Begin by clicking 'Generate Transcript' in the Toolkit"
                onChange={(e: any) =>
                  handleTextChange({
                    target: { name: "note", value: e.target.value },
                  } as any)
                }
              /> */}
            </div>
          </div>
        </DrawerBody>
        <DrawerStickyFooter>
          <div className="flex justify-end gap-2 w-full">
            <Button
              loading={isSaving}
              variant="default"
              onClick={handleSave}
              className="w-auto"
            >
              Create Qoo
            </Button>
          </div>
        </DrawerStickyFooter>
      </DrawerContent>
    </Drawer>
  );
};
