import { Button, Textarea } from "@/components/base";
import { Icons } from "@/components/icons";
import { assetService } from "@/service/content/asset.service";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AlertDialog } from "../alert/AlertDialog";

export const TestGenerateQoosModal = ({
  s3MediaId,
  isOpen,
  onClose,
}: {
  s3MediaId: number;
  isOpen: boolean;
  onClose: any;
}) => {
  const [qoos, setQoos] = useState([]);
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [activeQoo, setActiveQoo] = useState(null);

  const handleGenerate = async () => {
    setIsLoading(true);
    setQoos([]);
    const qoos = await assetService.testCreateAllResources(s3MediaId, prompt);
    setQoos(qoos);
    console.log(qoos);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      setPrompt(PROMPT_TEXT);
    }
  }, [isOpen]);

  useEffect(() => {
    setQoos([]);
    setPrompt(PROMPT_TEXT);
    setIsLoading(false);
  }, [s3MediaId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="p-4" minWidth="900px" minH="600px">
        <ModalHeader>
          <div>Test Generate Qoos</div>
        </ModalHeader>
        <div className="flex flex-row gap-2">
          <div className="w-[600px] border-r border-gray-300 pr-2">
            <div className="font-semibold mb-3">Results</div>
            <div className="flex flex-col gap-2">
              {qoos.map((qoo, index) => ( // Added index parameter
                <div 
                  key={qoo.id} 
                  className="hover:bg-gray-200 cursor-pointer" // Added hover styles
                  onClick={() => {setActiveQoo(qoo); setIsAlertOpen(true);}}
                >
                  <div key={qoo.id} className="font-semibold p-2">{index + 1}. {qoo.title}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-[450px] pt-[6px]">
            <Textarea
              rows={16}
              title="Prompt"
              className="!text-[black] p-4"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
            <div className="text-sm text-[gray] my-2">
              Modify the prompt to generate a script
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <Button
                onClick={handleGenerate}
                disabled={isLoading}
                loading={isLoading}
              >
                <Icons.Wand stroke="white" />
                Generate Qoos
              </Button>
            </div>
          </div>
        </div>
      </ModalContent>
      <AlertDialog
        header="Generated Qoos"
        isOpen={isAlertOpen}
        onClose={() => {setIsAlertOpen(false); setActiveQoo(null);}}
        onConfirm={() => {}}
        body={
            <div>
                {/* Container for the active Qoo details */}
                <div className="flex flex-col gap-4">
                    {/* Title of the active Qoo displayed prominently */}
                    <div className="font-bold text-lg">
                        {activeQoo?.title}
                    </div>
                    {/* Actions associated with the active Qoo */}
                    <div>
                        <div className="font-semibold">Actions</div>
                        <ul>
                            {activeQoo?.actions?.map((action, index) => (
                                <li key={index}>- {action}</li>
                            ))}
                        </ul>
                    </div>
                    {/* Bullet points related to the active Qoo */}
                    <div>
                        <div className="font-semibold">Bullet Points</div>
                        <ul>
                            {activeQoo?.bulletpoint?.map((bullet, index) => (
                                <li key={index}>- {bullet}</li>
                            ))}
                        </ul>
                    </div>
                    {/* Notes for the active Qoo */}
                    <div>
                        <div className="font-semibold">Notes</div>
                        {JSON.stringify(activeQoo?.note)}
                    </div>
                    {/* Tags associated with the active Qoo */}
                    <div>
                        <div className="font-semibold">Tags</div>
                        <ul>
                            {activeQoo?.tags?.map((tag, index) => (
                                <li key={index}>- {tag}</li>
                            ))}
                        </ul>
                    </div>
                    {/* Anecdote related to the active Qoo */}
                    <div>
                        <div className="font-semibold">Anecdote</div>
                        {JSON.stringify(activeQoo?.anecdote)}
                    </div>
                    {/* Transcript text for the active Qoo */}
                    <div>
                        <div className="font-semibold">Transcript Text</div>
                        {JSON.stringify(activeQoo?.transcript_text)}
                    </div>
                </div>
            </div>
        }
      />
    </Modal>
  );
};

const PROMPT_TEXT = `
        You are tasked with analyzing the provided transcript content to create high-level learning components that emphasize key business metrics, practical insights, and actionable steps. Follow these strict guidelines to ensure consistent, accurate, and verbatim results from the transcript without paraphrasing or altering any original metrics or values (e.g., percentages, dollar amounts, etc.), except where otherwise instructed.

        <content>
          {{transcriptText}}
        </content>

        <instructions>
           Key Instructions:
            1. Learning Outcome Caps Based on Text Size:
              •	For very short content (under 300 words): Generate 1-2 learning outcomes.
              •	For short content (300-800 words): Generate 3-4 learning outcomes.
              •	For medium-length content (800-1,500 words): Generate 5-6 learning outcomes.
              •	For long content (1,500-3,000 words): Generate 7-9 learning outcomes.
              •	For very long content (3,000+ words): Generate 7-12 learning outcomes based on the number of key insights, metrics, or business strategies present.
            2. Metrics, Ratios, and Numerical Insights:
              •	Use exact figures, percentages, ratios, dollar values ($), and pound values (£) as they appear in the transcript.
              •	Do not paraphrase, modify, or invent any metrics that are not explicitly stated.
              •	If a specific figure, percentage, or currency value is mentioned in the transcript, include it exactly as presented.
              •	If no metric or value is present in the transcript, do not add or invent any metrics. Stick strictly to the provided source.
            3. Contextualizing the Transcript:
              •	Provide necessary context around the provided transcript text to clarify its meaning, but do not modify or reinterpret the original text.
              •	If a metric or insight is discussed across multiple sections of the transcript, pull exact sentences from different sections, ensuring they support the same point without altering their original meaning.
              •	Do not use filler language, introductory phrases (e.g., “As one expert says,” or “Imagine this”), or paraphrasing. Always use the verbatim text from the transcript unless otherwise instructed.
            4. Learning Component Structure:
            Each learning component must follow this structure:
              •	Title: A concise, descriptive title summarizing the learning point.
              •	Action: A clear, actionable insight that can be applied. If relevant, include the exact metrics, numbers, or ratios from the transcript.
              •	Bullet Points: Provide three succinct, supporting bullet points that summarize key insights or rationale for the action. These should be clear, concise statements, not direct quotes from the transcript, unless quoting is necessary for accuracy.
              •	Bullet points should support the action and provide helpful context for applying the insight.
              •	Avoid using verbatim text in the bullet points unless it directly involves important metrics or specific terms. Focus on summarizing insights.
              •	Note: Provide context explaining the importance of the learning point without altering any figures or metrics. Add necessary context while ensuring that no original values are altered or invented.
              •	Tags: Provide three relevant tags for search purposes.
              •	Anecdote/Example: Provide a 4-5 sentence example from the transcript that includes verbatim transcript text. This section must pull text from a specific, distinct portion of the transcript that directly relates to the action. The text used here should be different from what is used in the “Transcript Text” section, ensuring that two separate sets of quotes are available.
              •	Transcript Text: Provide 3-5 sentences of verbatim transcript text pulled from a different portion of the transcript than the Anecdote/Example section. This ensures that you have two distinct sets of quotes to support the learning point. The transcript text should be contextually relevant to the action and can be pulled from multiple sections of the transcript to maintain full context.
            5. Use of Transcript Text:
              •	All transcript text must be presented exactly as it appears in the source. Do not paraphrase, change, or introduce ellipses (dot, dot, dot) between sentences.
              •	If specific figures, percentages, ratios, or dollar/pound values are mentioned, retain them exactly as they are. Do not alter or omit them.
              •	If no specific figure, percentage, or currency value is mentioned, do not add any. Stick to the text as it is presented in the original source.
            6. Verification of Metrics:
              •	If a percentage (%) is present in the transcript, ensure that it is correctly included in the output, but only if it is mentioned in the source.
              •	If a dollar value ($) or pound value (£) is present, ensure the correct currency symbol is retained. Do not convert between currencies, and do not invent figures if none are mentioned.
              •	All metrics and values must be directly pulled from the transcript and presented exactly as they are, without modification.
            7. Verification of Output:
              •	Ensure the final output is checked for strict adherence to these instructions. No filler language or paraphrasing is allowed.
              •	Triple-check that all transcript text, metrics, and figures are presented exactly as they appear in the original source.
              •	The context provided should clarify the point without altering or distorting the original meaning.
            8. Triple Verification Safeguard:
              •	Ensure that under no circumstances are filler phrases, paraphrasing, or modifications introduced into the output.
              •	The output should be checked and verified to confirm that no unnecessary commentary, intro phrases, or filler text has been included.
              •	The final output must be inspected to ensure complete compliance with these rules before submission.
            Parameters for API Execution:
              •	Model: GPT-4 (ensure you’re using the GPT-4 endpoint for more sophisticated results)
              •	Temperature: Set to 0.2 for structured, deterministic responses that avoid creative variability.
              •	Max Tokens: Adjust based on the length of the transcript, ensuring the full response can be generated without truncation. Recommend starting with 2000 tokens for long transcripts.
              •	Frequency Penalty: Set to 0.0 to avoid penalizing repetitive content when exact transcript text is needed.
              •	Presence Penalty: Set to 0.0 to prevent penalizing common phrases or structures that appear in the original transcript.
        </instructions>
`;
