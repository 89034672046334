import {
  Box,
  TableContainer as ChakraTableContainer,
  Table as ChakraTable,
  Th,
  Thead,
  Tr,
  Flex,
  Image,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { useEffect } from "react";

interface TableColumn {
  header: string;
  accessor: string;
  width?: string;
  disableSort?: boolean;
  cell?: (row: any) => React.ReactNode;
}

interface SortColumn {
  accessor: string;
  direction: number;
}

interface Props {
  columns: TableColumn[];
  data: any[];
  onRowClick?: (row: any, index: number) => void;
  sortColumn?: SortColumn;
  onSort?: (column: SortColumn) => void;
  isEmpty?: boolean;
  emptyChildren?: React.ReactNode;
  activeRowIndex?: number;
  [key: string]: any; // For any additional props
}

export const TableContainer: React.FC<Props> = ({
  columns,
  data,
  onRowClick = undefined,
  sortColumn = undefined,
  onSort = undefined,
  isEmpty = undefined,
  emptyChildren = undefined,
  activeRowIndex = undefined,
  ...props
}) => {
  const handleSort = (accessor: string) => {
    const sort = {
      accessor,
      direction:
        sortColumn?.accessor === accessor ? sortColumn?.direction ^ 1 : 0,
    };

    if (onSort) onSort(sort);
  };

  return !isEmpty ? (
    <Box
      {...props}
      style={{
        boxShadow: "0 0 10px 1px #ECECEC",
      }}
      border="1px solid #ECECEC"
      borderRadius="6px"
      overflowX="auto"
      h="fit-content"
    >
      <ChakraTable
        size="sm"
        variant="simple"
        style={{ tableLayout: "fixed", width: "100%", height: "1px" }}
      >
        <Thead>
          <Tr>
            {columns.map((column, i) => (
              <Th
                width={column.width ?? "full"}
                cursor={column.disableSort ? undefined : "pointer"}
                onClick={
                  column.disableSort
                    ? undefined
                    : () => handleSort(column.accessor)
                }
                color="brand.green"
                key={i}
                style={{
                  position: "sticky",
                  top: "0",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  zIndex: 1,
                  backgroundColor: "#FBFBF8",
                }}
              >
                <Flex
                  width={column.width}
                  flexDir="row"
                  justifyContent="space-between"
                >
                  {column.header}
                  {column.accessor === sortColumn?.accessor && (
                    <Box color="brand.green">
                      {sortColumn?.direction === 0 ? "▲" : "▼"}
                    </Box>
                  )}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, i) => (
            <Tr
              key={i}
              bg={activeRowIndex === i ? "lightgray" : undefined}
              _hover={
                onRowClick
                  ? { cursor: "pointer", backgroundColor: "lightgray" }
                  : undefined
              }
              onClick={onRowClick ? () => onRowClick(row, i) : undefined}
            >
              {columns.map((column, j) => (
                <Td key={j} h="50px" p={column.accessor === "notes" && 0}>
                  {column.cell ? (
                    <>{column.cell(row)}</>
                  ) : (
                    <>{row[column.accessor]}</>
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </Box>
  ) : emptyChildren ? (
    <>{emptyChildren}</>
  ) : (
    <Flex
      h="40vh"
      flexDir="column"
      justifyContent={"center"}
      alignItems="center"
    >
      <Box color="text-gray-400">{"Nothing here... yet."}</Box>
    </Flex>
  );
};
