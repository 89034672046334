import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@/components/base";
import { Label } from "@/components/third-party/shadcn/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/third-party/shadcn/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/third-party/shadcn/select";
import { Icons } from "@/components/icons";
import { sequenceService } from "@/service/content/sequence.service";

interface FilterOption {
  id: string;
  label: string;
  placeholder: string;
  items: Array<{ title: string; value: string | null }>;
}

interface FilterMenuProps {
  onApply: ({ path, module }: { path: number; module: number }) => void;
  initialValues?: Record<string, string | null>;
}

export const FilterMenu: React.FC<FilterMenuProps> = ({
  onApply,
  initialValues = {},
}) => {
  const [selectedValues, setSelectedValues] =
    useState<Record<string, string | null>>(initialValues);
  const [open, setOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [filterActive, setFilterActive] = useState(false);

  useEffect(() => {
    setSelectedValues(initialValues);
  }, [initialValues]);

  const handleValueChange = useCallback(
    (id: string, value: string | null) => {
      setSelectedValues((prevValues) => {
        const newValues = {
          ...prevValues,
          [id]: value,
        };

        return newValues;
      });
    },
    []
  );

  const handleApplyClick = () => {
    const payload = {
      path: +selectedValues.path,
      module: +selectedValues.module,
    };

    console.log("Emitting: ", payload);
    onApply(payload);
    setFilterActive(true);
    setOpen(false);
  };

  const handleResetClick = () => {
    setSelectedValues({});
    onApply({ path: null, module: null });
    setFilterActive(false);
    setOpen(false);
  };

  const fetchPathModules = async (pathId: string | null) => {
    if (!pathId || pathId === "none") {
      return [];
    }

    const modules = await sequenceService.getModules({
      pathId: parseInt(pathId),
    });

    setFilterOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      const moduleIndex = updatedOptions.findIndex(
        (option) => option.id === "module"
      );

      if (moduleIndex !== -1) {
        updatedOptions[moduleIndex] = {
          ...updatedOptions[moduleIndex],
          items: [
            { title: "None", value: "none" },
            ...modules.map((module: any) => ({
              title: module.title,
              value: module.id.toString(),
            })),
          ],
        };
      }
      return updatedOptions;
    });
  };

  const initFilter = async () => {
    const paths = await sequenceService.getPaths();

    setFilterOptions([
      {
        id: "path",
        label: "Path",
        placeholder: "Select path",
        items: [
          { title: "None", value: "none" },
          ...paths.map((path: any) => ({
            title: path.title,
            value: path.id.toString(),
          })),
        ],
      },
      {
        id: "module",
        label: "Module",
        placeholder: "Select module",
        items: [{ title: "None", value: "none" }],
      },
    ]);
  };

  useEffect(() => {
    initFilter();
  }, []);

  useEffect(() => {
    fetchPathModules(selectedValues.path);
  }, [selectedValues.path]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="relative">
          <Button variant="ghost">
            <Icons.ListFilter size={22} />
          </Button>

          <div className="absolute top-[6px] left-[10px]">
            {filterActive && (
              <div className="w-3 h-3 bg-primary rounded-full bg-red-400"></div>
            )}
            </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-80 bg-white">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">View Filter</h4>
            <p className="text-sm text-muted-foreground">
              Set the options for the filter.
            </p>
          </div>
          <div className="space-y-4">
            {filterOptions.map((option) => (
              <div key={option.id} className="flex items-center space-x-4">
                <Label htmlFor={option.id} className="flex-shrink-0 w-20">
                  {option.label}
                </Label>
                <div className="flex-grow">
                  <SelectInput
                    id={option.id}
                    onValueChange={(value) =>
                      handleValueChange(option.id, value)
                    }
                    placeholder={option.placeholder}
                    items={option.items}
                    value={selectedValues[option.id] || ""}
                  />
                </div>
              </div>
            ))}
            <div className="pt-4 flex justify-end gap-4">
              <Button variant="ghost" onClick={handleResetClick}>
                Reset
              </Button>
              <Button variant="outline" onClick={handleApplyClick}>
                Apply
              </Button>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

interface SelectInputProps {
  id: string;
  placeholder: string;
  onValueChange: (value: string | null) => void;
  items: Array<{ title: string; value: string | null }>;
  value: string;
}

const SelectInput: React.FC<SelectInputProps> = React.memo(
  ({ id, placeholder, onValueChange, items, value }) => {
    return (
      <Select onValueChange={onValueChange} value={value}>
        <SelectTrigger className="w-full px-3">
          <div className="max-w-[145px] overflow-hidden text-ellipsis whitespace-nowrap">
            <SelectValue placeholder={placeholder} />
          </div>
        </SelectTrigger>
        <SelectContent className="bg-white">
          <SelectGroup>
            {items.map((item, index) => (
              <SelectItem key={`${id}-${index}`} value={item.value ?? ""}>
                {item.title}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    );
  }
);

SelectInput.displayName = "SelectInput";
