import { Button, Box, Flex } from "@chakra-ui/react";

interface Props {
  count: number;
  totalItems: number;
  totalPage: number;
  pageNum: number;
  onChange: (page: number) => void;
}

export const TableFooter = (props) => {
  const {
    totalItems,
    totalPage,
    pageNum,
    onChange,
  } = props;

  const maxPageButtons = 6; // Maximum number of page buttons to display, including ellipses

  const generatePageRange = () => {
    let pages = [];

    if (totalPage <= maxPageButtons) {
      // If total pages is less than or equal to max buttons, show all pages
      for (let i = 1; i <= totalPage; i++) {
        pages.push(i);
      }
    } else {
      const sidePages = Math.floor((maxPageButtons - 3) / 2); // Number of pages on each side of the current page
      let start = Math.max(pageNum - sidePages, 2);
      let end = Math.min(pageNum + sidePages, totalPage - 1);

      pages.push(1); // Always include the first page

      // Decide if we need an ellipsis after the first page
      if (start > 2) {
        pages.push("...");
      }

      // Add the middle pages
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      // Decide if we need an ellipsis before the last page
      if (end < totalPage - 1) {
        pages.push("...");
      }

      pages.push(totalPage); // Always include the last page
    }

    return pages;
  };

  const pageButtons = generatePageRange().map((page, index) => (
    <Button
      h="24px"
      key={index}
      mx={1}
      _hover={{ bg: undefined }}
      padding={3}
      bg={page === pageNum ? "black" : "white"}
      colorScheme={page === pageNum ? "blue" : "gray"}
      onClick={() => page !== "..." && onChange(page)}
      disabled={page === "..."}
    >
      {page}
    </Button>
  ));

  return (
    <Flex h="fit-content" mt={4} justifyContent="flex-end">
      <Flex flexDir="row" gap="16px">
        <Box>{totalItems} Total</Box>
        <Box>{pageButtons}</Box>
      </Flex>
    </Flex>
  );
};
