import { Icons } from "@/components/icons";
import { MediaBadge } from "@/components/shared/media-badge";
import { AssetSequenceModal } from "@/components/shared/modal/sequence/AssetSequenceModal";
import { TranscriptModal } from "@/components/shared/modal/transcript/TranscriptModal";
import { HtmlVideoModal } from "@/components/shared/modal/video/HtmlVideoModal";
import { Button } from "@/components/third-party/shadcn/button";
import { Separator } from "@/components/third-party/shadcn/separator";
import { toast } from "@/components/third-party/shadcn/use-toast";
import { QoosListSection } from "@/components/views/toolkit/parts/QoosListSection";
import { convertIsoTime } from "@/lib/utils/date";
import { ContentAsset } from "@/models/asset.model";
import { assetService } from "@/service/content/asset.service";
import { transcriptService } from "@/service/content/transcript.service";
import { AlertDialog } from "@/components/shared/modal/alert/AlertDialog";
import { act, useEffect, useState } from "react";
import { Checkbox } from "@/components/base";
import { TestGenerateQoosModal } from "@/components/shared/modal/transcript/TestGenerateQoosModal";

export const AssetDetailDrawer = ({
  asset,
  onActiveAssetChange,
  onDeleted,
}: {
  asset: ContentAsset;
  onActiveAssetChange: () => void;
  onDeleted?: (asset: ContentAsset) => void;
}) => {
  const [videoOpen, setVideoOpen] = useState(false);
  const [transcriptOpen, setTranscriptOpen] = useState(false);
  const [sequenceOpen, setSequenceOpen] = useState(false);
  const [deleteContentOpen, setDeleteContentOpen] = useState(false);
  const [testGenerateQoosOpen, setTestGenerateQoosOpen] = useState(false);

  const [transcript, setTranscript] = useState(asset?.transcript);
  const [assetSequence, setAssetSequence] = useState(asset?.sequence);
  const [assetDeleteOpen, setAssetDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [includeDeleteTranscript, setIncludeDeleteTranscript] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const handleDeleteContent = async () => {
    await assetService
      .deleteAllResources(asset.id, includeDeleteTranscript)
      .then(() => {
        toast({
          title: "Delete successful",
          description: "Content deleted successfully",
        });

        if (includeDeleteTranscript) {
          setTranscript("");
        }

        onActiveAssetChange();
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Error deleting content",
        });
      })
      .finally(() => {
        setDeleteContentOpen(false);
      });
  };

  const handleSaveTranscript = async (newTranscript?: string) => {
    await transcriptService
      .save(asset.id, newTranscript ?? transcript)
      .then(() => {
        toast({
          title: "Transcript saved!",
          description:
            "Transcript has been saved successfully. You can now generate content with the updated transcript.",
        });

        setCanSave(false);
      });
  };

  const handleDeleteAsset = async () => {
    setDeleteLoading(true);
    const assetId = asset?.id;
    await assetService
      .delete(assetId)
      .then(() => {
        toast({
          title: "Success",
          description: "Asset deleted",
        });
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Error deleting asset",
        });
      })
      .finally(() => {
        setDeleteLoading(false);
        setAssetDeleteOpen(false);
        onDeleted(asset);
      });
  };

  useEffect(() => {
    if (!asset?.id) return;

    setIncludeDeleteTranscript(false);
    setAssetSequence(asset?.sequence);
    setTranscript(asset?.transcript);
  }, [asset]);

  useEffect(() => {
    if (!!transcript?.length && canSave) {
      handleSaveTranscript(transcript);
    }
  }, [canSave, transcript]);

  return (
    <div className="flex flex-col flex-1 gap-4 w-[450px] p-4 border-[1px] rounded-lg border-gray-200">
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <div className="text-[18px] font-semibold">
                {asset?.originalName}
              </div>
            </div>
            <div
              className="cursor-pointer rounded-full p-1"
              onClick={() => setAssetDeleteOpen(true)}
            >
              <Icons.Trash stroke="red" size={20} />
            </div>
          </div>
        </div>
        <div className="text-[14px]">
          {asset?.uploaded ? (
            <>Uploaded on {convertIsoTime(asset?.uploadDate || "")}</>
          ) : (
            <>Asset is currently uploading...</>
          )}
        </div>
        <Button
          variant="outline"
          className="flex flex-1 flex-row gap-1 mt-2 items-center cursor-pointer"
          onClick={() => setVideoOpen(true)}
        >
          <Icons.Play fill="black" width="18" />
          <div className="font-semibold">Watch Source Video</div>
        </Button>

        <Button size="sm" onClick={() => setTestGenerateQoosOpen(true)}>
          Test Create Qoos
        </Button>
      </div>

      <Separator />
      <div className="flex flex-row gap-1 text-[14px] items-center gap-10 justify-center w-full">
        {!!transcript?.length && (
          <div
            className="flex flex-row gap-1 items-center  cursor-pointer"
            onClick={() => setTranscriptOpen(true)}
          >
            <Icons.DocumentOutline stroke="black" width="18" />
            <div className="font-semibold">Transcript</div>
          </div>
        )}

        <div
          className="flex flex-row gap-1 items-center  cursor-pointer"
          onClick={() => setSequenceOpen(true)}
        >
          <Icons.Slider stroke="black" width="18" />
          <div className="font-semibold">Sequence</div>
        </div>

        {!!asset?.stream?.qoos?.length && (
          <div
            className="flex flex-row gap-1 items-center  cursor-pointer"
            onClick={() => setDeleteContentOpen(true)}
          >
            <Icons.Trash stroke="black" width="18" />
            <div className="font-semibold">Delete Content</div>
          </div>
        )}
      </div>

      <Separator />

      {!!asset?.stream?.qoos?.[0] && (
        <QoosListSection asset={asset} handleChange={onActiveAssetChange} />
      )}

      <HtmlVideoModal
        isOpen={videoOpen}
        onClose={() => setVideoOpen(false)}
        src={asset?.presignedUrl || ""}
      />

      <TranscriptModal
        s3MediaId={asset?.id}
        isOpen={transcriptOpen}
        onClose={() => setTranscriptOpen(false)}
        transcript={transcript}
      />

      <AssetSequenceModal
        id={asset?.id}
        sequence={assetSequence}
        onSubmit={(sequence) => {
          console.log(sequence);
          setAssetSequence(sequence);
        }}
        isOpen={sequenceOpen}
        onClose={() => setSequenceOpen(false)}
      />

      <AlertDialog
        loading={deleteLoading}
        header="Confirm Delete"
        variant="destructive"
        body="Are you sure you want to delete this asset?"
        isOpen={assetDeleteOpen}
        onClose={() => setAssetDeleteOpen(false)}
        onConfirm={handleDeleteAsset}
      />

      <AlertDialog
        header="Confirm Delete Content"
        body={
          <div className="flex flex-col gap-4">
            <div>
              Are you sure you want to delete content for this asset? This will
              overwrite the existing Transcript, Qoos, and Videos.
            </div>
            <Checkbox
              title="Delete Transcript"
              onChange={(val) => {
                setIncludeDeleteTranscript(val);
              }}
            />
          </div>
        }
        variant="destructive"
        isOpen={deleteContentOpen}
        onClose={() => {
          setDeleteContentOpen(false);
          setIncludeDeleteTranscript(false);
        }}
        onConfirm={handleDeleteContent}
      />

      <TestGenerateQoosModal
        s3MediaId={asset?.id}
        isOpen={testGenerateQoosOpen}
        onClose={() => setTestGenerateQoosOpen(false)}
      />
    </div>
  );
};
